.filepond--item {
    width: calc(100% - .5em);
}

.filepond--new-memory .filepond--item {
    width: calc(50% - .5em);
}

.filepond--root {
    font-family: 'opensans', sans-serif;
}

/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
    cursor: pointer;
}

/* the text color of the drop label*/
.filepond--drop-label {
    color: #555;
}

/* underline color for "Browse" button */
.filepond--label-action {
    color:#32735F;
    text-decoration-color: #32735F;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
    border: 1px solid #32735F;
}

/* the border radius of the drop area */
.filepond--panel-root {
    border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
    border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
    background-color: #222222;
}

/* the background color of the drop circle */
.filepond--drip-blob {
    background-color: #999;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
    background-color: #222222;
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
    color: #EFF2F2;
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
    box-shadow: 0 0 0 0.125em #EFF2F2;
}

/* the text color of the file status and info labels */
.filepond--file {
    color: #EFF2F2;
}

/* error state color */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
    background-color: #FF8F60;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
    background-color: #32735F;
}

.filepond--image-preview-overlay-idle {
    color: rgba(40, 40, 40, 0.85);
}

.filepond--image-preview-overlay-success {
    color: #32735F;
}

.filepond--image-preview-overlay-failure {
    color: #FF8F60;
}

.filepond--image-preview {
    background-color: #9E9E9E;
}

.pintura-editor {
    --color-background: 255, 255, 255;
    --color-foreground: 0, 0, 0;
    --color-focus: 50, 115, 95;
    --color-primary: #32735F;
    --color-primary-dark: #32735F;
    --color-primary-text: #EFF2F2;
    --color-secondary: #C9E0E0;
    --button-cursor: pointer;
    --font-family: 'opensans', sans-serif;
    --font-size: 19px;
}

